.datepicker
{
    width:100%;
    justify-content:center;
    text-align:center;
    font-size:20px;
    border-color:green;
    border-radius:20px;
    display:inline;
}
.lekcja
{
    width:50%;
    justify-content:center;
    text-align:center;
    font-size:20px;
    font-weight:bold;
    border-color:green;
    border:2px solid;
    padding:10px;
    border-radius:20px;
    display:inline;
    margin:0;
    box-sizing:border-box;
}
.custom-file-upload {
    display: inline-block;
    padding: 7px ;

    color: white;
    background-color: #3ead5a;
    border-radius: 5px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}
.codeBlock {
    background-color: '#f4f4f4';
    padding: 10px;
    border-radius: 4px;
    font-Family: Consolas, monospace;
    font-Size: 14px;
    white-Space: 'pre';
  }