.datepicker
{
    width:100%;
    justify-content:center;
    text-align:center;
    font-size:20px;
    border-color:green;
    border-radius:20px;
    display:inline;
}
.lekcja
{
    width:50%;
    justify-content:center;
    text-align:center;
    font-size:20px;
    font-weight:bold;
    border-color:green;
    border:2px solid;
    padding:10px;
    border-radius:20px;
    display:inline;
    margin:0;
    box-sizing:border-box;
}
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* Add this to your CSS */
.green-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #4CAF50; /* Change border color to green (#4CAF50) */
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.green-checkbox:checked {
  background-color: #4CAF50; /* Change background color when checked */
}
.green-textbox
{
  border: 2px solid #4CAF50;
}